import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ArrowDropDown } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Skeleton,
    Typography,
    alpha,
} from '@mui/material';
import { getDataFromOrder } from 'entities/Order';
import {
    getSelectedOrder,
    getSelectedOrderIsLoading,
} from 'entities/Order/model/selectors/ordersSelectors';
import { PropertyCardRow } from 'entities/Property';
import { getPropertyItem } from 'features/fetchPropertyById/model/selectors/fetchPropertyByIdSelectors';
import { useFeatureFlag } from 'shared/lib/hooks/useFeatureFlag';

export const OrderViewPageDetails = () => {
    const { t } = useTranslation();
    const renovationEnabled = useFeatureFlag('renovation_enabled');
    const currentProperty = useSelector(getPropertyItem);
    const isLoading = useSelector(getSelectedOrderIsLoading);
    const order = useSelector(getSelectedOrder);

    const {
        creatorName,
        createdAtDay,
        createdAtTime,
        updatedAtDay,
        updatedAtTime,
        lastUpdatedBy,
        orderType,
    } = getDataFromOrder(order);

    const createdTime = `${createdAtDay} ${createdAtTime}`;
    const updatedTime = `${updatedAtDay} ${updatedAtTime}`;

    return (
        <Box>
            <Accordion
                sx={(theme) => ({
                    bgcolor: alpha(theme.palette.grey[500], 0.5),
                    borderRadius: '12px',
                })}
            >
                <AccordionSummary
                    sx={{ m: '8px 0' }}
                    expandIcon={
                        <ArrowDropDown
                            sx={(theme) => ({
                                color: alpha(theme.palette.common?.black, 0.3),
                            })}
                        />
                    }
                >
                    <Typography typography="openSans.subtitle1Medium">
                        {t('Details')}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ p: '12px' }}>
                    {!isLoading ? (
                        <Box>
                            <PropertyCardRow
                                name={t('Property')}
                                value={currentProperty?.propertyNameLegal}
                                bordered
                            />
                            {renovationEnabled && (
                                <PropertyCardRow
                                    name={t('Type')}
                                    value={orderType}
                                    bordered
                                />
                            )}
                            <PropertyCardRow
                                name={t('Created')}
                                value={createdTime}
                                bordered
                            />

                            <PropertyCardRow
                                name={t('Created By')}
                                value={creatorName}
                                bordered
                            />

                            <PropertyCardRow
                                name={t('Updated')}
                                value={updatedTime}
                                bordered
                            />
                            <PropertyCardRow
                                name={t('Updated By')}
                                value={lastUpdatedBy}
                                bordered
                            />
                        </Box>
                    ) : (
                        <Box display="flex" flexDirection="column" gap="2px">
                            <Skeleton variant="rounded" height="52px" />
                            <Skeleton variant="rounded" height="52px" />
                            <Skeleton variant="rounded" height="52px" />
                        </Box>
                    )}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
