import { useEffect, useRef } from 'react';
import { useLocation, useMatch, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';
import {
    getCatalogItems,
    getCatalogItemsAreLoading,
    getSelectedCatalogItems,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { MoveToInventoryCatalogItemButton } from 'features/moveToInventoryCatalogItem';
import { RemoveCatalogItem } from 'features/removeCatalogItem';
import { PropertyCatalogItemsMoveTo } from 'widgets/PropertyCatalog/models/consts/propertyCatalogItemsListTypes';
import { getRouteGlobalCatalog } from 'shared/const/router';
import { PropertyCatalogItemsList } from './PropertyCatalogItemsList';

interface PropertyCatalogItemsOutletProps {
    hasCatalogItems: boolean;
    showBulkPannel: boolean;
    moveTo: PropertyCatalogItemsMoveTo;
}

export const PropertyCatalogItemsOutlet = () => {
    const { hasCatalogItems, showBulkPannel, moveTo } =
        useOutletContext<PropertyCatalogItemsOutletProps>();

    const selectedItems = useSelector(getSelectedCatalogItems);
    const location = useLocation();
    const catalogItems = useSelector(getCatalogItems);
    const loading = useSelector(getCatalogItemsAreLoading);
    const isGlobalCatalog = useMatch(
        getRouteGlobalCatalog(':propertyId') + '/*',
    );
    const currentPageIsOrders = useMatch('/orders' + '/*');

    const isSingleItem = Object.keys(selectedItems || {})?.length === 1;
    const isOrderEditing = location.state?.selectedOrderId;

    const scrollableRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (scrollableRef.current) {
            scrollableRef.current.scrollTo(0, 0);
        }
    }, [catalogItems]);

    return (
        <Box
            flex="1 1"
            p="0 16px 16px"
            display="flex"
            flexDirection="column"
            sx={{ overflowY: 'scroll' }}
            ref={scrollableRef}
        >
            {hasCatalogItems && !isGlobalCatalog ? (
                <Box
                    display="flex"
                    gap="24px"
                    justifyContent="flex-end"
                    p="16px 0"
                    bgcolor="background.paper"
                    zIndex="drawer"
                    minHeight="55px"
                >
                    {showBulkPannel ? (
                        <>
                            <RemoveCatalogItem isSingleItem={isSingleItem} />
                            <MoveToInventoryCatalogItemButton
                                isSingleItem={isSingleItem}
                            />
                        </>
                    ) : null}
                </Box>
            ) : null}
            <PropertyCatalogItemsList
                items={catalogItems}
                loading={loading}
                moveTo={moveTo}
                quickEdit={isOrderEditing}
                isOrderPage={!!currentPageIsOrders}
            />
        </Box>
    );
};
