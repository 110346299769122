/* eslint-disable max-len */
import { Box, Button, Typography } from '@mui/material';
import { getSelectedCatalogItemsTotal } from 'entities/CatalogItem/model/selectors/catalogItemSelectors';

import {
    getCanEditOwnSelectedOrder,
    getCanEditSelectedOrder,
    getCanRejectSeletedOrder,
} from 'entities/Order/model/selectors/ordersPermissions';

import {
    getSelectedOrderId,
    getSelectedOrderIsLoading,
} from 'entities/Order/model/selectors/ordersSelectors';
import { getUserCanAddOrderItem } from 'entities/User/model/selectors/userPermissionsSeletors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OrderGroupedItems } from 'widgets/OrderGroupedItems';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { RejectOrderButton } from 'features/changeOrderStatus';
import { OrderViewPageApproveButton } from './OrderViewPageApproveButton';
import { AddItemsToOrderButton } from 'features/AddItemsToOrder';
import { getProposalFilterIsActive } from 'features/OrderProposalFilter/model/selectors/orderProposalFilteSelectors';
import { EmptyPlaceholder } from 'shared/ui/EmptyPlaceholder/EmptyPlaceholder';
import openBoxSearch from 'shared/assets/svg/open-box-search.svg';

interface OrderViewPageItemsProps {
    groupedItems: Record<string, CatalogItem[]>;
    onBack: () => void;
}

export const OrderViewPageItems = ({
    groupedItems,
    onBack,
}: OrderViewPageItemsProps) => {
    const { t } = useTranslation();
    const totalItemsCount = useSelector(getSelectedCatalogItemsTotal);
    const orderId = useSelector(getSelectedOrderId);
    const orderIsLoading = useSelector(getSelectedOrderIsLoading);
    const canEditOwnSelectedOrder = useSelector(getCanEditOwnSelectedOrder);
    const canEditSelectedOrder = useSelector(getCanEditSelectedOrder);
    const canAddOrderItem = useSelector(getUserCanAddOrderItem);
    const canReject = useSelector(getCanRejectSeletedOrder);
    const filterIsActive = useSelector(getProposalFilterIsActive);

    const canAddItems =
        (canEditSelectedOrder && canAddOrderItem) || canEditOwnSelectedOrder;

    const hasItems = totalItemsCount > 0;

    const showNoFilteredItems =
        filterIsActive &&
        Object.keys(groupedItems).length === 0 &&
        !orderIsLoading;

    return (
        <Box height={'calc(100vh - 112px)'} position="relative">
            <Box p="16px 16px 96px" height="100%">
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent={!hasItems ? 'center' : undefined}
                    gap="8px"
                    height="100%"
                    sx={{ overflowY: 'scroll' }}
                >
                    <Box mb="16px">
                        {!orderIsLoading && canAddItems && (
                            <AddItemsToOrderButton />
                        )}
                    </Box>
                    {showNoFilteredItems && (
                        <Typography
                            pb="24px"
                            sx={{ opacity: 0.5 }}
                            typography="openSans.body2Medium"
                        >
                            {t('There is no Items in Selected Filter')}.
                        </Typography>
                    )}
                    {!(hasItems && orderIsLoading) &&
                        Object.entries(groupedItems).map(
                            ([categoryId, items]) => (
                                <OrderGroupedItems
                                    key={categoryId}
                                    items={items}
                                    categoryId={categoryId}
                                />
                            ),
                        )}
                    <Box
                        height="100%"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        {!hasItems && !orderIsLoading && (
                            <Box width="366px">
                                <EmptyPlaceholder image={openBoxSearch}>
                                    <Typography
                                        mt="16px"
                                        sx={{ opacity: 0.6 }}
                                        typography="openSans.body2Medium"
                                        align="center"
                                    >
                                        {t(
                                            "The order doesn't have any items yet, so you can't confirm or reject an empty order",
                                        )}
                                        .
                                    </Typography>
                                </EmptyPlaceholder>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Box
                display="flex"
                justifyContent="end"
                p="12px 16px 16px"
                bgcolor="background.paper"
                position="absolute"
                width="100%"
                bottom="0px"
                borderRadius="16px"
            >
                <Box display="flex" flexDirection="column">
                    <Box display="flex" alignSelf={'flex-end'} gap="16px">
                        <Button onClick={onBack}>{t('Close')}</Button>
                        {canReject ? (
                            <RejectOrderButton
                                orderId={orderId}
                                isNoItemsInOrder={!hasItems}
                            />
                        ) : (
                            <Button sx={{ display: 'none' }} variant="outlined">
                                {t('Save Draft')}
                            </Button>
                        )}
                        <OrderViewPageApproveButton
                            isNoItemsInOrder={!hasItems}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
