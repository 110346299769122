import { createSlice } from '@reduxjs/toolkit';
import { OrderSchema } from '../types/OrderSchema';
import { OrderStatuses } from '../consts/orderStatuses';
import { createOrder } from '../services/createOrder/createOrder';
import { fetchOrder } from '../services/fetchOrder/fetchOrder';
import { editOrderItem } from 'features/editItemsInOrder/model/services/editOrderItem/editOrderItem';
import { editPurchaseOrderNumber } from 'features/OrderProposalFilter/model/services/editPurchaseOrderNumber';

const initialState: OrderSchema = {
    dataIsLoading: false,
    poNumberIsLoading: false,
    orderSummary: 0,
    searchParams: { status: OrderStatuses.DRAFT },
};

export const OrderSlice = createSlice({
    name: 'Order',
    initialState,
    reducers: {
        setSearchParamsStatus: (state, action) => {
            state.searchParams.status = action.payload;
        },
        clearSelectedOrder: (state) => {
            state.selectedData = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrder.fulfilled, (state, action) => {
                const order = action.payload.data;
                state.selectedData = order;

                state.orderSummary = order.items.reduce(
                    (next, item) => next + item.itemInfoFrozen.price,
                    0,
                );

                state.dataIsLoading = false;
            })
            .addCase(editOrderItem.fulfilled, (state, action) => {
                const { id } = action.payload;
                state.selectedData.items = state.selectedData.items.map(
                    (item) => (item.id === id ? action.payload : item),
                );
            })
            .addCase(fetchOrder.rejected, (state, action) => {
                state.dataIsLoading = false;
                state.orderSummary = 0;
                state.error = action.payload;
            })
            .addCase(createOrder.pending, (state) => {
                state.error = null;
                state.dataIsLoading = true;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.selectedData = action.payload;
                state.dataIsLoading = false;
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.dataIsLoading = false;
                state.error = action.payload;
            })
            .addCase(editPurchaseOrderNumber.pending, (state) => {
                state.poNumberIsLoading = true;
            })
            .addCase(editPurchaseOrderNumber.fulfilled, (state, action) => {
                const order = action.payload;
                state.selectedData = order;
                state.poNumberIsLoading = false;
            })
            .addCase(editPurchaseOrderNumber.rejected, (state, action) => {
                state.poNumberIsLoading = false;
            });
    },
});

export const { actions: orderActions } = OrderSlice;
export const { reducer: orderReducer } = OrderSlice;
