import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { DialogBoxContent } from '../DialogBoxContent/DialogBoxContent';
import { Loader } from '../Loader/Loader';

interface RemoveOrderModalProps {
    isShowDeleteOrder: boolean;
    isLoadingDeleteOrder: boolean;
    title: string;
    text: string;
    onCloseDeleteOrder: () => void;
    onSubmitDeleteOrder: () => void;
}

export default memo(
    ({
        isShowDeleteOrder,
        title,
        text,
        onCloseDeleteOrder,
        onSubmitDeleteOrder,
        isLoadingDeleteOrder,
    }: RemoveOrderModalProps) => {
        const { t } = useTranslation();

        const cancelOrderModal = useCallback(() => {
            if (!isLoadingDeleteOrder) {
                onCloseDeleteOrder();
            }
        }, [isLoadingDeleteOrder, onCloseDeleteOrder]);

        const submitOrderModal = useCallback(() => {
            if (!isLoadingDeleteOrder) {
                onSubmitDeleteOrder();
            }
        }, [isLoadingDeleteOrder, onSubmitDeleteOrder]);

        return (
            isShowDeleteOrder && (
                <Dialog open>
                    <DialogBoxContent
                        onClose={cancelOrderModal}
                        buttons={
                            <Box
                                display="flex"
                                justifyContent="flex-end"
                                width="100%"
                                gap="10px"
                            >
                                <Button
                                    onClick={cancelOrderModal}
                                    color="primary"
                                >
                                    {t('No')}
                                </Button>
                                <Button
                                    variant="contained"
                                    startIcon={
                                        isLoadingDeleteOrder && (
                                            <Loader color="inherit" />
                                        )
                                    }
                                    disabled={isLoadingDeleteOrder}
                                    onClick={submitOrderModal}
                                >
                                    {t('Yes')}
                                </Button>
                            </Box>
                        }
                    >
                        <Box maxWidth="600px">
                            <Typography
                                mb="20px"
                                typography="poppins.subtitle2"
                            >
                                {title}
                            </Typography>
                            <Typography mb="8px" typography="openSans.body2">
                                {text}.
                            </Typography>
                            <Typography mb="10px" typography="openSans.body2">
                                {t('Are you sure')}?
                            </Typography>
                        </Box>
                    </DialogBoxContent>
                </Dialog>
            )
        );
    },
);
