import { useTranslation } from 'react-i18next';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    TableContainer,
    Box,
    Button,
    Link,
} from '@mui/material';
import { DeleteOutline } from '@mui/icons-material';
import { Vendor } from 'entities/Vendors';
import { Image } from 'widgets/Image';
import SellerIcon from 'shared/assets/svg/SellerIcon.svg';
import ServiceIcon from 'shared/assets/svg/ServiceIcon.svg';
import { parsePhoneNumber } from 'libphonenumber-js';

export const VendorsTable = ({
    vendorsList,
    isLoadingVendors,
    isMainVendorsPage,
    onClickRow,
    onDeleteVendor,
    deleteVendorId,
}: {
    vendorsList: Vendor[];
    isLoadingVendors: boolean;
    isMainVendorsPage?: boolean;
    onClickRow: (arg0: string | Vendor) => void;
    onDeleteVendor?: (arg0: Vendor) => void;
    deleteVendorId?: string;
}) => {
    const { t } = useTranslation();

    return (
        <TableContainer>
            <Table
                sx={{
                    marginTop: '30px',
                    '& td': {
                        padding: '6px 16px',
                        borderBottom: 'none',
                        height: '50px',
                    },
                    '& tr': {
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                    },
                    '& th': {
                        paddingTop: '0px',
                        paddingBottom: '10px',
                    },
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="left">
                            <Typography typography="openSans.captionMedium">
                                {t('Name')}
                            </Typography>
                        </TableCell>
                        <TableCell align="left">
                            <Typography typography="openSans.captionMedium">
                                {t('Specialties')}
                            </Typography>
                        </TableCell>
                        {isMainVendorsPage ? (
                            <>
                                <TableCell align="left">
                                    <Typography typography="openSans.captionMedium">
                                        {t('Primary Contact')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="left">
                                    <Typography typography="openSans.captionMedium">
                                        {t('Phone / Email')}
                                    </Typography>
                                </TableCell>
                            </>
                        ) : (
                            <TableCell align="left">
                                <Typography typography="openSans.captionMedium">
                                    {t('Added to property')}
                                </Typography>
                            </TableCell>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isLoadingVendors &&
                        vendorsList?.map((row) => (
                            <TableRow
                                key={row.id}
                                onClick={
                                    isMainVendorsPage
                                        ? () => onClickRow(row.id)
                                        : () =>
                                              !row.isAddToProperty &&
                                              onClickRow(row)
                                }
                                sx={{
                                    cursor: isMainVendorsPage
                                        ? 'pointer'
                                        : !row.isAddToProperty
                                        ? 'pointer'
                                        : 'default',
                                    opacity: row.isAddToProperty ? 0.3 : 1,
                                    '&:hover': {
                                        backgroundColor: '#3F6EB726',
                                    },
                                }}
                            >
                                <TableCell
                                    sx={{
                                        width: '78px',
                                        padding: '6px 0 6px 16px !important',
                                    }}
                                >
                                    <Box
                                        display="flex"
                                        gap={3}
                                        alignItems="center"
                                    >
                                        <Image
                                            title={
                                                row.sellerType === 'seller'
                                                    ? 'Seller'
                                                    : 'Service Provider'
                                            }
                                            src={
                                                row.sellerType === 'seller'
                                                    ? String(SellerIcon)
                                                    : String(ServiceIcon)
                                            }
                                            style={{
                                                aspectRatio: '1/1',
                                                width: '12px',
                                                backgroundColor: 'inherit',
                                            }}
                                        />
                                        {row?.logo ? (
                                            <Image
                                                src={row.logo}
                                                style={{
                                                    aspectRatio: '1/1',
                                                    height: '32px',
                                                    border: '1px solid #E9E8E8',
                                                    borderRadius: '4px',
                                                }}
                                                imageStyle={{
                                                    objectFit: 'contain',
                                                }}
                                            />
                                        ) : (
                                            <Box
                                                style={{
                                                    display: 'inline-flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    width: '32px',
                                                    height: '32px',
                                                    backgroundColor: '#808080',
                                                    color: '#FFFFFF',
                                                    borderRadius: '4px',
                                                    fontSize: '16px',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {row.title
                                                    .charAt(0)
                                                    .toUpperCase()}
                                            </Box>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Typography typography="openSans.body2Medium">
                                        {row.title}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography typography="openSans.body2">
                                        {row.speciality?.length <= 2
                                            ? row.speciality.join(', ')
                                            : `${row.speciality?.length} specailties`}
                                    </Typography>
                                </TableCell>
                                {isMainVendorsPage ? (
                                    <>
                                        <TableCell>
                                            <Typography typography="openSans.body2">
                                                {row.primaryContact}
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            sx={{
                                                boxSizing: 'border-box',
                                                maxWidth: '240px',
                                            }}
                                        >
                                            {row?.phone && (
                                                <Typography typography="openSans.body2">
                                                    {parsePhoneNumber(
                                                        row.phone,
                                                        'US',
                                                    ).formatNational()}
                                                </Typography>
                                            )}
                                            <Typography
                                                color="#5492F4"
                                                sx={{
                                                    display: 'block',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                }}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                href={`mailto:${row.email}`}
                                                component={Link}
                                                title={row.email}
                                                typography={
                                                    row?.phone
                                                        ? 'openSans.caption'
                                                        : 'openSans.body2'
                                                }
                                            >
                                                {row.email}
                                            </Typography>
                                        </TableCell>
                                    </>
                                ) : (
                                    <TableCell>
                                        <Typography typography="openSans.body2">
                                            {row.isAddToProperty
                                                ? 'Added'
                                                : 'Not Added'}
                                        </Typography>
                                    </TableCell>
                                )}
                                {isMainVendorsPage && (
                                    <TableCell align="center">
                                        <Button
                                            sx={{
                                                '& span': {
                                                    margin: 0,
                                                },
                                                padding: '5px',
                                                minWidth: 'auto',
                                            }}
                                            startIcon={
                                                <DeleteOutline
                                                    color={
                                                        deleteVendorId ===
                                                        row.id
                                                            ? 'primary'
                                                            : 'disabled'
                                                    }
                                                    sx={{
                                                        fontSize:
                                                            '25px !important',
                                                    }}
                                                />
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onDeleteVendor(row);
                                            }}
                                        />
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
