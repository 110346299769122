import { useMemo } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Theme,
    alpha,
} from '@mui/material';
import {
    getCatalogItems,
    getSelectedCatalogItem,
} from 'entities/CatalogItem/model/selectors/catalogItemSelectors';
import { catalogItemActions } from 'entities/CatalogItem/model/slices/CatalogItemSlice';
import { CatalogItem } from 'entities/CatalogItem/model/types/CatalogItemSchema';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getRouteForbidden } from 'shared/const/router';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch';
import { useInitialEffect } from 'shared/lib/hooks/useInitialEffect';
import { CatalogItemImage } from 'shared/ui/CatalogItemImage';
import { isArray } from 'shared/lib/lodash/lodash';
import { useUnmountEffect } from 'shared/lib/hooks/useUnmountEffect';
import { CatalogItemCheckbox } from 'features/CatalogItemCheckbox';
import { useGetItemDataUpdate } from 'features/CatalogItemCheckbox/lib/useGetItemDataUpdate';
import { getUserCanSeePrices } from 'entities/User/model/selectors/userPermissionsSeletors';

export const OrderViewItemDetailedInfo = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const params = useParams();

    const item = useSelector(getSelectedCatalogItem);
    const catalogItems = useSelector(getCatalogItems);
    const canSeePrices = useSelector(getUserCanSeePrices);

    const selectedItem = item as CatalogItem;
    const items = catalogItems as CatalogItem[];

    const { idToEdit, qty } = useGetItemDataUpdate({
        item: selectedItem,
    });

    const pricePerItem =
        selectedItem?.selectedProposal?.price || selectedItem?.price;

    const totalPrice = qty * Number(pricePerItem) || null;

    useInitialEffect(() => {
        if (!selectedItem && isArray(items)) {
            let foundItem = items.find((item) => item.id === params?.itemId);

            if (foundItem) {
                dispatch(catalogItemActions.setSelectedItem(foundItem));
            } else {
                navigate(getRouteForbidden());
            }
        }
    });

    useUnmountEffect(() => {
        dispatch(catalogItemActions.setSelectedItem(null));
    });

    const priceStyles = useMemo(
        () => ({
            fontSize: '16px',
            fontStyle: 'italic',
            letterSpacing: '0.15px',
            color: (theme: Theme) => alpha(theme.palette.common.black, 0.7),
        }),
        [],
    );

    return (
        <TableContainer>
            <Table
                sx={{
                    '& .MuiTableHead-root .MuiTableCell-root': {
                        borderBottom: 'none',
                    },
                    '& .MuiTableBody-root .MuiTableCell-root': {
                        '&:first-of-type': {
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderLeft: '1px solid',
                            borderColor: 'grey.500',
                            borderRadius: '4px 0px 0px 4px',
                        },
                        '&:last-of-type': {
                            borderTop: '1px solid',
                            borderBottom: '1px solid',
                            borderRight: '1px solid',
                            borderRadius: '0px 4px 4px 0px',
                            borderColor: 'grey.500',
                        },
                        '&': {
                            borderTop: '1px solid',
                            borderRadius: '0px 4px 4px 0px',
                            borderColor: 'grey.500',
                            p: '12px',
                        },
                    },
                    borderCollapse: 'separate',
                    borderSpacing: '0 14px',
                }}
            >
                <TableHead
                    sx={{
                        '& th': {
                            paddingTop: '0px',
                            paddingBottom: '0px',
                        },
                    }}
                >
                    <TableRow>
                        <TableCell>
                            <Typography typography="openSans.captionMedium">
                                {t('Item')}
                            </Typography>
                        </TableCell>

                        <TableCell>
                            <Typography
                                typography="openSans.captionMedium"
                                align="center"
                            >
                                {t('UOM')}
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography
                                typography="openSans.captionMedium"
                                align="center"
                            >
                                {t('Qty')}
                            </Typography>
                        </TableCell>

                        {canSeePrices && (
                            <>
                                <TableCell align="right">
                                    <Typography
                                        noWrap
                                        typography="openSans.captionMedium"
                                    >
                                        {t('Starting At')}
                                    </Typography>
                                </TableCell>
                                <TableCell align="right">
                                    <Typography typography="openSans.captionMedium">
                                        {t('Total')}
                                    </Typography>
                                </TableCell>
                            </>
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {selectedItem && (
                        <TableRow key={selectedItem.id}>
                            <TableCell>
                                <Box display="flex">
                                    <Box
                                        width="124px"
                                        height="124px"
                                        flex="0 0 124px"
                                        borderRadius="4px"
                                        p="4px"
                                    >
                                        <CatalogItemImage
                                            src={selectedItem.thumbnailUrl}
                                        />
                                    </Box>
                                    <Box>
                                        <Typography
                                            typography={
                                                'openSans.subtitle1Medium'
                                            }
                                        >
                                            {selectedItem.title}
                                        </Typography>
                                        <Typography
                                            typography={'openSans.body2'}
                                        >
                                            {'SKU'}: {selectedItem.sku}
                                        </Typography>
                                    </Box>
                                </Box>
                            </TableCell>
                            <TableCell
                                sx={{
                                    verticalAlign: 'top',
                                    boxSizing: 'border-box',
                                    width: '44px',
                                    maxWidth: '44px',
                                }}
                                align="center"
                            >
                                <Typography typography="openSans.body2">
                                    {selectedItem.uom}
                                </Typography>
                            </TableCell>
                            <TableCell
                                sx={{
                                    verticalAlign: 'top',
                                    boxSizing: 'content-box',
                                    width: '132px',
                                    maxWidth: '132px',
                                }}
                                align="center"
                            >
                                <CatalogItemCheckbox
                                    isOrderDetails
                                    hideSwitcher
                                    minimized
                                    isNoGap
                                    showQtyCheckbox
                                    deleteDisabled
                                    isActive
                                    quickEdit
                                    qty={qty}
                                    itemId={selectedItem.id}
                                    itemUid={selectedItem.uuid}
                                    itemIdToEdit={idToEdit}
                                    isInOrder
                                />
                            </TableCell>

                            {canSeePrices && (
                                <>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            verticalAlign: 'top',
                                            boxSizing: 'content-box',
                                            width: '70px',
                                            maxWidth: '70px',
                                        }}
                                    >
                                        <Typography {...priceStyles}>
                                            ${pricePerItem}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="right"
                                        sx={{
                                            verticalAlign: 'top',
                                            boxSizing: 'content-box',
                                            width: '70px',
                                            maxWidth: '70px',
                                        }}
                                    >
                                        <Typography {...priceStyles}>
                                            ${totalPrice?.toFixed(2) || '0.00'}
                                        </Typography>
                                    </TableCell>
                                </>
                            )}
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
