import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    CatalogItem,
    CatalogItemInOrder,
    CatalogItemSchema,
    GlobalCatalogItem,
} from '../types/CatalogItemSchema';
import { has, set, unset, remove, find } from 'shared/lib/lodash/lodash';
import { fetchGlobalCatalogItems } from '../services/fetchGlobalCatalogItems/fetchGlobalCatalogItems';
import { ResponseWithPagination } from 'shared/types/responses';
import { fetchCatalogItems } from '../services/fetchCatalogItems/fetchCatalogItems';
import { revertCatalogStores } from 'shared/ui/store/resetStoresAction';
import { fetchOrder } from 'entities/Order';
import { mapCatalogItemFromOrder } from 'entities/CatalogItem/lib/mapCatalogItemFromOrder';
import { editOrderItem } from 'features/editItemsInOrder/model/services/editOrderItem/editOrderItem';

const initialState: CatalogItemSchema = {
    listIsLoading: false,
    showDetailedView: false,
    selectedItems: {},
};

export const CatalogItemSlice = createSlice({
    name: 'CatalogItem',
    initialState,
    reducers: {
        toggleMultiselectItem: (state, action: PayloadAction<string>) => {
            const newData = { ...state.selectedItems };
            const id = action.payload;

            if (has(newData, id)) {
                unset(newData, id);
            } else {
                set(newData, id, { qty: 1 });
            }

            state.selectedItems = newData;
        },
        addMultiselectItem: (
            state,
            action: PayloadAction<{ id: string; uid?: string }>,
        ) => {
            const newData = { ...state.selectedItems };
            const { id, uid } = action.payload;

            if (!has(newData, id)) {
                set(newData, id, { qty: 1, uid });
                state.selectedItems = newData;
            }
        },
        removeMultiselectItem: (state, action: PayloadAction<string>) => {
            const newData = { ...state.selectedItems };
            const id = action.payload;

            if (has(newData, id)) {
                unset(newData, id);
                state.selectedItems = newData;
            }
        },
        removeItem: (state, action: PayloadAction<string>) => {
            const newSelectedItems = { ...state.selectedItems };
            const newData = [...(state?.dataList || [])] as CatalogItem[];
            const idOfDelteItem = action.payload;

            if (has(newSelectedItems, idOfDelteItem)) {
                unset(newSelectedItems, idOfDelteItem);
                state.selectedItems = newSelectedItems;
            }
            remove(
                newData,
                (item) => 'id' in item && item.id === idOfDelteItem,
            );

            state.dataList = newData;
        },
        clearAllItems: (state) => {
            state.dataList = [];
        },
        setShowDetailedView: (state, action: PayloadAction<boolean>) => {
            state.showDetailedView = action.payload;
        },
        setSelectedItem: (state, action: PayloadAction<CatalogItem>) => {
            state.selectedItem = action.payload;
        },
        setSelectedItemById: (state, action: PayloadAction<string>) => {
            state.selectedItem = find(
                state.dataList,
                (item: CatalogItem | GlobalCatalogItem) =>
                    ('id' in item ? item.id : item.uid) === action.payload,
            );
        },
        closeDetailedItem: (state) => {
            state.selectedItem = null;
            if (state.showDetailedView) {
                state.showDetailedView = false;
            }
        },
        removeSingleItemByUidFromSelection: (
            state,
            action: PayloadAction<string>,
        ) => {
            const newData = { ...state.selectedItems };

            const idToRemove = Object.entries(newData).find(
                ([itemId, item]) => item.uid == action.payload,
            );

            if (idToRemove) {
                unset(newData, idToRemove[0]);
                state.selectedItems = newData;
            }
        },
        removeMultipleItemsByUid: (state, action: PayloadAction<string[]>) => {
            const newData = [...state.dataList] as CatalogItem[];
            const deletedItemUuids = action.payload;

            state.dataList = newData.filter((item) => {
                return !deletedItemUuids.includes(item.uuid);
            });
        },
        updateItems: (
            state,
            action: PayloadAction<Record<CatalogItem['id'], CatalogItem>>,
        ) => {
            const updatedData = (state.dataList as CatalogItem[]).map(
                (item) => {
                    if (item.id in action.payload) {
                        return action.payload[item.id];
                    }
                    return item;
                },
            );

            const updatedDataArray = Object.entries(action.payload);

            updatedDataArray.forEach(([id, item]) => {
                set(updatedData, id, item);
            });

            state.dataList = updatedData;
        },
        updateItem: (state, action: PayloadAction<CatalogItem>) => {
            const updatedList = [...state.dataList] as CatalogItem[];
            const updatedItem = action.payload;

            const foundIndex = updatedList.findIndex(
                (item) => item.id === updatedItem.id,
            );

            if (foundIndex || foundIndex === 0) {
                updatedList[foundIndex] = updatedItem;
            }

            state.dataList = updatedList;
        },
        setItemQuantity: (state, action) => {
            const newData = { ...state.selectedItems };
            const { id, value } = action.payload;

            if (has(newData, id)) {
                set(newData, `${id}.qty`, value);
            }

            state.selectedItems = newData;
        },
        setSelection: (state, action: PayloadAction<CatalogItemInOrder[]>) => {
            const newSelectedItems = {};
            action.payload.forEach((item) => {
                const catalogItemData = mapCatalogItemFromOrder(item);
                set(
                    newSelectedItems,
                    item.itemInfoFrozen.id,
                    catalogItemData.selection,
                );
            });

            state.selectedItems = newSelectedItems;
        },
        removeAllSelection: (state) => {
            state.selectedItems = {};
        },
        setLoading: (state, action) => {
            state.listIsLoading = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(revertCatalogStores, () => initialState)
            .addCase(fetchOrder.fulfilled, (state, action) => {
                const { data, isCatalog } = action.payload;
                state.listIsLoading = false;

                const newData: CatalogItem[] = [];
                const newSelectedItems = {};
                data.items.forEach((item) => {
                    const catalogItemData = mapCatalogItemFromOrder(item);
                    newData.push(catalogItemData.info);

                    const id = isCatalog ? item.itemInfoFrozen.id : item.id;
                    set(newSelectedItems, id, catalogItemData.selection);
                });

                state.selectedItems = newSelectedItems;
                state.dataList = newData;
            })
            .addCase(fetchOrder.rejected, (state, action) => {
                state.listIsLoading = false;
                state.error = action.payload;
            })
            .addCase(editOrderItem.fulfilled, (state, action) => {
                const { id } = action.payload;

                const newData: CatalogItem[] = [];
                const newSelectedItems = state.selectedItems;
                set(newSelectedItems, id, action.payload);

                const findIndex = state.dataList.findIndex(
                    (item) => 'id' in item && item.id === id,
                );

                if (findIndex || findIndex === 0) {
                    const catalogItemData = mapCatalogItemFromOrder(
                        action.payload,
                    );

                    newData.push(catalogItemData.info);
                    state.dataList[findIndex] = catalogItemData.info;
                    state.selectedItem = catalogItemData.info;
                }

                state.selectedItems = newSelectedItems;
                state.listIsLoading = false;
            })
            .addCase(fetchGlobalCatalogItems.pending, (state) => {
                state.error = null;
                state.listIsLoading = true;
            })
            .addCase(
                fetchGlobalCatalogItems.fulfilled,
                (
                    state,
                    action: PayloadAction<
                        ResponseWithPagination<GlobalCatalogItem[]>
                    >,
                ) => {
                    state.listIsLoading = false;
                    state.dataList = action.payload?.items;
                },
            )
            .addCase(fetchGlobalCatalogItems.rejected, (state, action) => {
                state.listIsLoading = false;
                state.dataList = null;
                state.error = action.payload;
            })
            .addCase(fetchCatalogItems.pending, (state) => {
                state.error = null;
                state.listIsLoading = true;
            })
            .addCase(fetchCatalogItems.fulfilled, (state, action) => {
                state.listIsLoading = false;
                state.dataList = action.payload;
            })
            .addCase(fetchCatalogItems.rejected, (state, action) => {
                state.listIsLoading = false;
                state.dataList = null;
                state.error = action.payload;
            });
    },
});

export const { actions: catalogItemActions } = CatalogItemSlice;
export const { reducer: catalogItemReducer } = CatalogItemSlice;
